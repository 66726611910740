/* Style for the container of tabs */
.tabs-container {
    width: 100%;

    align-items: center;
  }
  
  /* Style for the tab headings */
  .nav-tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: blue;
    color: white;
    padding: 15px; /* Increase the padding for larger tabs */
  }
  
  /* Style for individual tabs */
  .nav-item {
    margin-right: 20px; /* Adjust the margin as needed for wider tabs */
  }
  
  /* Style for the tab content */
  .tab-content {
    width: 100%; /* Take up the full width */
    margin: 0 auto;
  }
  
  /* Style for active tab */
  .nav-link.active {
    background-color: white;
    color: blue;
    border: 1px solid blue;
    border-bottom-color: transparent;
  }
  