.home-user {
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: row; /* Đặt hướng của flex container là ngược lại, văn bản sẽ luôn nằm bên trái */
  }
  
  .home-user-gif {
    width: 90px; /* Đặt kích thước ảnh là 90px */
    height: auto;
  }
  
  .home-user-text-container {
    text-align: left;
    margin-left: 10px; /* Điều chỉnh khoảng cách giữa ảnh và vùng chứa văn bản */
    max-width: 200px;
    color: white;    
  }
  
  .home-user-text {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: #273de7;
    animation: blinker 1.5s linear infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  } 

  /* HomeUser.css */
.loading-gif {
  /* Thêm quy tắc CSS để tùy chỉnh kiểu dáng của hình ảnh loading */
  width: 40px;
}

  
  